<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div v-show="!processDisabled" class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 관련공정 -->
          <c-process
            label="LBL0001705"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 도면분류 -->
          <c-select
            type="search"
            :comboItems="diagramKindItems"
            itemText="diagramKindName"
            itemValue="diagramClassCd"
            label="LBL0001703"
            name="diagramClassCd"
            v-model="searchParam.diagramClassCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 도면 -->
          <c-text
            label="LBL0001001"
            name="diagramTitle"
            v-model="searchParam.diagramTitle">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <!-- 공정 도면 목록 -->
    <c-table
      ref="table"
      title="LBL0001704"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :columnSetting="false"
      :isExcelDown="false"
      :isFullScreen="false"
      rowKey="psiDiagramId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'diagram-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          processCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      diagramKindItems: [],
      searchParam: {
        plantCd: '',
        processCd: '',
        deptCd: '',
        diagramClassCd: null,
        diagramTitle: '',
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'diagramKindName',
            field: 'diagramKindName',
            // 도면분류
            label: 'LBL0001703',
            align: 'center',
            style: 'width: 250px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'diagramNo',
            field: 'diagramNo',
            // 도면번호
            label: 'LBL0001084',
            align: 'center',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            // 도면
            label: 'LBL0001001',
            align: 'left',
            sortable: false,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'LBLREV',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
        ],
        height: '650px',
        data: [],
      },
      editable: true,
      listUrl: '',
      kindlistUrl: '',
    };
  },
  computed: {
    processDisabled() {
      return Boolean(this.popupParam.processCd);
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.psi.diagram.list.url;
      this.kindlistUrl = selectConfig.psi.diagramkind.url;
      this.getKind();

      if (this.popupParam.processCd) {
        if (this.popupParam.processCd.indexOf('WTE') === -1) {
          this.searchParam.processCd = this.$_.clone(this.popupParam.processCd)
        } else {
          this.searchParam.processCd = null;
        }
      }
      this.getList();
    },
    getKind() {
      this.$http.url = this.kindlistUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.diagramKindItems = _result.data;
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다. 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
